import React, { Fragment } from 'react'
import { withStyles, createStyles, Divider } from '@material-ui/core'
import { MuiProps, muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import QuantiteParStatutDynamicTable from './_quantiteParStatutDynamicTable'
import LargeProductHeader from '../_largeProductHeader'
import ProduitInfos from '../_produitInfos'
import { ReferenceProduit, OrdreDeFabrication, LastCommentaireReferenceProduit } from '../../models'

type LigneParStatutProps = {
    of: OrdreDeFabrication
    referenceProduit: ReferenceProduit
    lastCommentaire?: LastCommentaireReferenceProduit
}

function LigneParStatut({ of, referenceProduit, lastCommentaire, classes }: MuiProps & LigneParStatutProps) {
    return (
        <Fragment>
            <LargeProductHeader
                numero={of.numero}
                statut={referenceProduit.statut}
                fabricantLabel={of.fabricant.label}
                sousActivite={referenceProduit.produit.sousActivite}
                type={referenceProduit.produit.type}
                saison={of.saison}
                typeOrdreDeFabrication={of.typeOrdreDeFabrication}
                groupage={of.groupage}
                codeProduit={referenceProduit.produit.code}
                lastCommentaire={lastCommentaire}
            />
            <Divider />
            <section className={classes.lineRoot}>
                <div className={classes.produitInfos}>
                    <ProduitInfos
                        modele={referenceProduit.produit.modele}
                        matiere={referenceProduit.produit.matiere}
                        sketch={referenceProduit.produit.sketch}
                        couleur={referenceProduit.produit.couleur}
                        gender={referenceProduit.produit.gender}
                    />
                </div>
                <div className={classes.tableContainer}>
                    <QuantiteParStatutDynamicTable
                        isFlexGrow={true}
                        quantiteStatuts={referenceProduit.quantiteStatuts}
                    />
                </div>
            </section>
        </Fragment>
    )
}

let styles = () =>
    createStyles({
        lineRoot: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
        tableContainer: { width: '65%' },
        produitInfos: {
            width: '35%',
        },
    })

export default withStyles(styles, muiOptions)(LigneParStatut)
