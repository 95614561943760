import React, { Component } from 'react'
import {
    Tooltip,
    withStyles,
    createStyles,
    Button,
    Fab,
    Table,
    TablePagination,
    TableRow,
    TableBody,
} from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { MuiProps, muiOptions, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import { t } from 'i18next'
import { observer } from 'mobx-react'
import { observable, makeAutoObservable } from 'mobx'
import { FilterAsn, FiltersPost, FlatOfReferenceProduit } from '../../ordreDeFabrication/models'
import api from '../../infrastructure/api'
import filtersState from '../../infrastructure/toolbar/filters/filterGlobalState'
import filterHelper from '../../infrastructure/toolbar/filters/filterHelper'
import { state as filterState } from '../../infrastructure/toolbar/filters/components/filters'
import { state as asnFilter } from '../../infrastructure/toolbar/asnFilters/components/asnFilters'
import asnFilterHelper, { loadCompleteDataSetAsn } from '../../infrastructure/toolbar/asnFilters/asnFilterHelper'
import asnGlobalFiltersState from '../../infrastructure/toolbar/asnFilters/asnGlobalFiltersState'
import authToken from '../../infrastructure/authToken'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type PageNavigationType = 'firstPage' | 'nextPage' | 'prevPage' | 'lastPage'

interface IPagerState {
    items: any[]
    itemsDisplayed: any[]
    currentPage: number
}

export class PagerState<T> implements IPagerState {
    @observable items: T[]
    @observable itemsDisplayed: T[]
    @observable currentPage: number
    @observable pageCount: number

    constructor() {
        makeAutoObservable(this)
    }
}

export function CreatePagerAsnState<T>(
    itemsCollection: T[],
    startingPage: number,
    numberOfElementPerPage: number,
): PagerState<T> {
    let newState = new PagerState<T>()
    newState.items = itemsCollection
    newState.itemsDisplayed = itemsCollection
    newState.currentPage = startingPage
    newState.pageCount = Math.ceil(asnGlobalFiltersState.advancedShippingNoticeCount / numberOfElementPerPage)

    return newState
}

class PagerProps<T> {
    numberOfElementPerPage: number
    pagerState: PagerState<T>
}

@observer
class Pager<T> extends Component<MuiProps & PagerProps<T>, {}> {
    componentDidMount() {
        this.props.pagerState.pageCount = Math.ceil(
            asnGlobalFiltersState.advancedShippingNoticeCount / this.props.numberOfElementPerPage,
        )
    }

    async UNSAFE_componentWillUpdate(nextProps: MuiProps & PagerProps<T>) {
        if (nextProps.pagerState.itemsDisplayed.length === 0) {
            const userProfile = authToken.getAuthProfile()
            let fabricant: any
            if (userProfile) fabricant = userProfile.fabricantCode
            if (!fabricant || fabricant == 'undefined') fabricant = 'none'

            let result = filterHelper.filtersFlat(filterState.appliedFilters)
            let resultAsn = filterHelper.filtersFlat(asnFilter.appliedFilters)

            let body: FilterAsn = {
                ManufacturerCode: [],
                NumberAsn: [],
                NumberWo: [],
                SeasonCode: [],
                TypeWO: [],
                Group: [],
                StatusWo: [],
                SubActivityCode: [],
                TypeCode: [],
                Gender: [],
                ModelCode: [],
                MaterialCode: [],
                ColorCode: [],
                Status: [],
                ShippingDateFrom: '',
                ShippingDateTo: '',
            }
            result.forEach(x => {
                switch (x.type) {
                    case 'color':
                        body.ColorCode.push(x.key)
                        break
                    case 'manufacturer':
                        body.ManufacturerCode.push(x.key)
                        break
                    case 'season':
                        body.SeasonCode.push(x.key)
                        break
                    case 'typeWO':
                        body.TypeWO.push(x.key)
                        break
                    case 'group':
                        body.Group.push(x.key)
                        break
                    case 'status':
                        body.StatusWo.push(x.key)
                        break
                    case '#WO':
                        body.NumberWo.push(x.key)
                        break
                    case 'subActivity':
                        body.SubActivityCode.push(x.key)
                        break
                    case 'type':
                        body.TypeCode.push(x.key)
                        break
                    case 'gender':
                        body.Gender.push(x.key)
                        break
                    case 'model':
                        body.ModelCode.push(x.key)
                        break
                    case 'material':
                        body.MaterialCode.push(x.key)
                        break
                }
            })
            resultAsn.forEach(x => {
                switch (x.type) {
                    case 'Status':
                        if (x.key === 'Not Received' || x.key === 'Non reçu' || x.key === 'Non ricevuto')
                            x.key = 'NonRecu'
                        else if (
                            x.key === 'Completely Received' ||
                            x.key === 'Complètement reçu' ||
                            x.key === 'Completamente ricevuto'
                        )
                            x.key = 'CompletementRecu'
                        else {
                            x.key = 'PartiellementRecu'
                        }
                        body.Status.push(x.key)
                        break

                    case 'NumberAsn':
                        body.NumberAsn.push(x.key)
                        break

                    case 'ShippingDateFrom':
                        body.ShippingDateFrom = x.key
                        break

                    case 'ShippingDateTo':
                        body.ShippingDateTo = x.key
                }
            })

            if (
                body.ManufacturerCode.length !== 0 ||
                body.NumberAsn.length !== 0 ||
                body.SeasonCode.length !== 0 ||
                body.TypeWO.length !== 0 ||
                body.StatusWo.length !== 0 ||
                body.Group.length !== 0 ||
                body.SubActivityCode.length !== 0 ||
                body.TypeCode.length !== 0 ||
                body.Gender.length !== 0 ||
                body.ModelCode.length !== 0 ||
                body.MaterialCode.length !== 0 ||
                body.ColorCode.length !== 0 ||
                body.NumberWo.length !== 0 ||
                body.Status.length !== 0 ||
                body.ShippingDateFrom !== '' ||
                body.ShippingDateTo !== ''
            ) {
                let asn = await api.post<any>(`asn/filters/${this.props.pagerState.currentPage + 1}/${fabricant}`, {
                    ManufacturerCode: body.ManufacturerCode,
                    Status: body.Status,
                    Number: body.NumberAsn,
                    NumberWo: body.NumberWo,
                    ShippingDateFrom: body.ShippingDateFrom,
                    ShippingDateTo: body.ShippingDateTo,
                })
                asnGlobalFiltersState.advancedShippingNoticeToDisplay = asn.asn
            } else {
                let asn = await api.post<any>(`asn/filters/${this.props.pagerState.currentPage + 1}/${fabricant}`, {})
                asnGlobalFiltersState.advancedShippingNoticeToDisplay = asn.asn
            }
        }
    }

    handleNavigationInPages(type: PageNavigationType) {
        let goFirstPage = () => (this.props.pagerState.currentPage = 0)

        let goLastPage = () => (this.props.pagerState.currentPage = this.props.pagerState.pageCount - 1)

        let goNextPage = () => {
            this.props.pagerState.currentPage = this.props.pagerState.currentPage + 1
        }

        let goPrevPage = () => (this.props.pagerState.currentPage = this.props.pagerState.currentPage - 1)

        switch (type) {
            case 'firstPage':
                goFirstPage()
                break
            case 'nextPage':
                goNextPage()
                break
            case 'prevPage':
                goPrevPage()
                break
            case 'lastPage':
                goLastPage()
                break
        }
        this.props.pagerState.itemsDisplayed = []
        window.scroll(0, 0)
    }

    onChangePage = (event: any, newPage: number) => {
        if (event !== null) {
            this.props.pagerState.currentPage = newPage
        }
    }

    tablePaginationActions = () => {
        let classes = this.props.classes
        let currentPage = this.props.pagerState.currentPage
        return (
            <div className={classes.pagerNav}>
                <Tooltip title={t('pager.firstPage')} placement="top">
                    <div>
                        <Fab
                            onClick={() => this.handleNavigationInPages('firstPage')}
                            disabled={currentPage === 0}
                            className={classes.pagerButton}>
                            <Icons.FirstPage />
                        </Fab>
                    </div>
                </Tooltip>
                <Tooltip title={t('pager.prevPage')} placement="top">
                    <div>
                        <Fab
                            onClick={() => this.handleNavigationInPages('prevPage')}
                            disabled={currentPage === 0}
                            className={classes.pagerButton}>
                            <Icons.KeyboardArrowLeft />
                        </Fab>
                    </div>
                </Tooltip>
                <Tooltip title={t('pager.nextPage')} placement="top">
                    <div>
                        <Fab
                            onClick={() => this.handleNavigationInPages('nextPage')}
                            disabled={currentPage >= this.props.pagerState.pageCount - 1}
                            className={classes.pagerButton}>
                            <Icons.KeyboardArrowRight />
                        </Fab>
                    </div>
                </Tooltip>
                <Tooltip title={t('pager.lastPage')} placement="top">
                    <div>
                        <Fab
                            onClick={() => this.handleNavigationInPages('lastPage')}
                            disabled={currentPage >= this.props.pagerState.pageCount - 1}
                            className={classes.pagerButton}>
                            <Icons.LastPage />
                        </Fab>
                    </div>
                </Tooltip>
            </div>
        )
    }

    labelRowsDisplayed = (current, total) => {
        return (
            <div className={this.props.classes.labelRowsDisplayed}>{`${t('pager.page')} : ${current} / ${total}`}</div>
        )
    }

    render() {
        let classes = this.props.classes
        return (
            <Table>
                <TableBody>
                    <TableRow>
                        <TablePagination
                            className={classes.paginationRow}
                            classes={{ spacer: classes.paginationSpacer }}
                            count={this.props.pagerState.items.length}
                            page={this.props.pagerState.currentPage}
                            rowsPerPage={this.props.numberOfElementPerPage}
                            rowsPerPageOptions={[this.props.numberOfElementPerPage]}
                            onPageChange={(event, page) => {
                                this.onChangePage(event, page)
                            }}
                            ActionsComponent={this.tablePaginationActions}
                            labelDisplayedRows={() =>
                                this.labelRowsDisplayed(
                                    this.props.pagerState.currentPage + 1,
                                    this.props.pagerState.pageCount,
                                )
                            }
                        />
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
}

let styles = theme =>
    createStyles({
        pagerNav: {
            display: 'flex',
            width: '22%',
            justifyContent: 'space-between',
            marginLeft: '2%',
        },
        pagerButton: {
            ...(defaultStyles.buttonFab as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
        labelRowsDisplayed: {
            fontSize: '1.2rem',
            width: '150px',
        },
        paginationRow: {
            borderBottom: 'none',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        paginationSpacer: {
            display: 'none',
        },
    })

export default withStyles(styles, muiOptions)(Pager)
