import React from 'react'
import moment from 'moment'
import { createStyles, withStyles, Tooltip } from '@material-ui/core'
import { Typography, Button } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { t } from 'i18next'
import { MuiProps, muiOptions, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { OrdreDeFabrication, ReferenceProduit } from '../../models'
import StatutSelector from './statutSelector'
import OfStatutChip from '../../../common/ofStatutChip'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

type HeaderProps = {
    of: OrdreDeFabrication
    referenceProduit: ReferenceProduit
    handleChangeStatut: (ordreDeFabricationNumero: string, produitCode: string, newStatut: string) => void
}

function Header({ of, referenceProduit, handleChangeStatut, classes }: MuiProps & HeaderProps) {
    return (
        <div className={classes.header}>
            <div className={classes.leftHeader}>
                <OfStatutChip className={classes.numero} label={of.numero} statut={referenceProduit.statut} />
                <StatutSelector
                    handleChange={handleChangeStatut}
                    ordreDeFabricationNumero={of.numero}
                    produitCode={referenceProduit.produitCode}
                    selectedItem={referenceProduit.statut}
                />
                <Typography variant={'subtitle1'} className={classes.typoHeader}>
                    {t('detailsOf.creation') + ' ' + moment(of.dateCreation).format(t('date.format.date'))}
                </Typography>
                <Tooltip title={t('product.manufacturer')}>
                    <Typography variant={'subtitle1'} className={classes.typoHeader}>
                        {of.fabricant.label}
                    </Typography>
                </Tooltip>
                <Tooltip title={t('product.season')}>
                    <Typography variant={'subtitle1'} className={classes.typoHeader}>
                        {!!of.saison ? of.saison.description : ''}
                    </Typography>
                </Tooltip>
                <Tooltip title={t('product.typeWO')}>
                    <Typography variant={'subtitle1'} className={classes.typoHeader}>
                        {of.typeOrdreDeFabrication && t('typeOf.'.concat(of.typeOrdreDeFabrication.toLowerCase()))}
                    </Typography>
                </Tooltip>
                <Typography variant={'subtitle1'} className={classes.typoHeader}>
                    {of.groupage}
                </Typography>
            </div>
            <div className={classes.rightHeader}>
                <Button variant="contained" className={classes.button} onClick={() => history.back()}>
                    <Icons.ArrowBack />
                    {t('common.back')}
                </Button>
            </div>
        </div>
    )
}

let styles = () =>
    createStyles({
        header: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        leftHeader: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            flexGrow: 1,
        },
        rigntHeder: {
            ...defaultStyles.flexRow,
            flexGrow: 1,
        },
        numero: {
            marginLeft: '40px',
            marginRight: '20px',
        },
        typoHeader: {
            margin: '5px 20px',
        },
        button: {
            ...(defaultStyles.button as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
    })

export default withStyles(styles, muiOptions)(Header)
