import React from 'react'
import { FormControl, createStyles, TextField, withStyles } from '@material-ui/core'
import { t } from 'i18next'
import { MuiProps, muiOptions } from '../../../materialUiThemeProvider'

type FilterSelectorProps = {
    handleChange: (type: string, key: string) => void
    type: string
    parse: boolean
}

let FilterInput = ({ handleChange, type, classes, parse }: MuiProps & FilterSelectorProps) => {
    let inputValue: string | undefined = ''

    let checkValueBeforeChange = (): void => {
        if (inputValue == undefined) return
        if (parse) {
            let [isValueOk, value] = inputValue.tryParse()
            if (isValueOk) handleChange(type, value.toString())
        } else {
            handleChange(type, inputValue)
        }
    }

    let onChangeQuantite = (targetElement: HTMLInputElement) => {
        if (parse) {
            let [hasCorrectValue, value] = targetElement.value.tryParse()
            if (hasCorrectValue) inputValue = targetElement.value
            else if (value == 0) inputValue = undefined
            targetElement.value = inputValue || ''
        } else {
            inputValue = targetElement.value.trim()
            targetElement.value = inputValue
        }
    }

    return (
        <FormControl className={classes.formControl}>
            <TextField
                placeholder={t('product.'.concat(type.toLowerFirstChar()))}
                type="search"
                className={classes.innerFormControl}
                margin="normal"
                inputProps={{ maxLength: 10 }}
                onBlur={event => {
                    checkValueBeforeChange()
                    let target = event.target as HTMLInputElement
                    target.value = ''
                }}
                onChange={event => onChangeQuantite(event.target as HTMLInputElement)}
                onKeyPress={event => {
                    if (event.key === 'Enter') {
                        checkValueBeforeChange()
                        let target = event.target as HTMLInputElement
                        target.value = ''
                    }
                }}
            />
        </FormControl>
    )
}

let styles = () =>
    createStyles({
        formControl: {
            width: '80%',
            margin: '0% 2%',
        },
        innerFormControl: {
            marginTop: '0px !important',
            marginBottom: '0px !important',
        },
    })

export default withStyles(styles, muiOptions)(FilterInput)
