import React, { Fragment } from 'react'
import { withStyles, createStyles, Typography, Button, Tooltip } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
// import { navigateTo } from '../../infrastructure/navigation'
import { MuiProps, muiOptions, defaultStyles } from '../../infrastructure/materialUiThemeProvider'
import { SousActivite, TypeProduit, Saison, LastCommentaireReferenceProduit } from '../models'
import OfStatutChip from '../../common/ofStatutChip'
import BadgeCommentaire from './liste/_badgeCommentaire'
import { t } from 'i18next'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { useNavigate } from 'react-router-dom'

type LargeProductHeaderProps = {
    numero: string
    statut: string
    fabricantLabel: string
    sousActivite?: SousActivite
    type?: TypeProduit
    saison: Saison
    typeOrdreDeFabrication?: string
    groupage: string | null
    codeProduit?: string
    lastCommentaire?: LastCommentaireReferenceProduit
    style?: any
}

function LargeProductHeader({ classes, style = { width: '100%' }, ...props }: MuiProps & LargeProductHeaderProps) {
    const navigate = useNavigate()

    return (
        <section className={classes.largeProductHeader} style={style}>
            <div className={classes.leftHeader}>
                <OfStatutChip className={classes.numero} label={props.numero} statut={props.statut} />
                {!!props.fabricantLabel && (
                    <Tooltip title={t('product.manufacturer')}>
                        <Typography className={classes.headerLabel} variant="subtitle1">
                            {props.fabricantLabel}
                        </Typography>
                    </Tooltip>
                )}
                {!!props.sousActivite && (
                    <Tooltip title={t('product.subActivity')}>
                        <Typography className={classes.headerLabel} variant="subtitle1">
                            {props.sousActivite.description}
                        </Typography>
                    </Tooltip>
                )}
                {!!props.type && (
                    <Tooltip title={t('product.type')}>
                        <Typography className={classes.headerLabel} variant="subtitle1">
                            {props.type.description}
                        </Typography>
                    </Tooltip>
                )}
                {!!props.saison && (
                    <Tooltip title={t('product.season')}>
                        <Typography className={classes.headerLabel} variant="subtitle1">
                            {props.saison.description}
                        </Typography>
                    </Tooltip>
                )}
                {!!props.typeOrdreDeFabrication && (
                    <Tooltip title={t('product.typeWO')}>
                        <Typography className={classes.headerLabel} variant="subtitle1">
                            {t('typeOf.'.concat(props.typeOrdreDeFabrication.toLowerCase()))}
                        </Typography>
                    </Tooltip>
                )}
                {!!props.groupage && (
                    <Tooltip title={t('product.group')}>
                        <Typography className={classes.headerLabel} variant="subtitle1">
                            {props.groupage}
                        </Typography>
                    </Tooltip>
                )}
                {!!props.codeProduit && (
                    <Tooltip title={t('product.productCode')}>
                        <Typography className={classes.headerLabel} variant="subtitle1">
                            {props.codeProduit}
                        </Typography>
                    </Tooltip>
                )}
            </div>
            <Fragment>
                <div className={classes.badgeCommentaire}>
                    <BadgeCommentaire commentaireReferenceProduit={props.lastCommentaire} />
                </div>
                <Button
                    variant="contained"
                    className={classes.buttonGoToDetails}
                    onClick={() => navigate(`/detailsOfSku/${props.numero}/${props.codeProduit}/`)}>
                    <Icons.YoutubeSearchedFor />
                    {t('detailsOf.viewDetails')}
                </Button>
            </Fragment>
        </section>
    )
}

let styles = theme =>
    createStyles({
        largeProductHeader: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
        },
        leftHeader: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-around',
            alignItems: 'center',
        },
        numero: {
            width: 70,
            minWidth: 70,
            marginTop: 5,
            marginLeft: 40,
            marginRight: 26,
        },
        headerLabel: {
            margin: '.3em .5em',
            whiteSpace: 'nowrap',
        },
        badgeCommentaire: {
            margin: 'auto 1rem auto auto',
        },
        buttonGoToDetails: {
            ...(defaultStyles.button as CSSProperties),
            ...(defaultStyles.buttonNavigation as CSSProperties),
        },
    })

export default withStyles(styles, muiOptions)(LargeProductHeader)
