import Guid from 'guid'

export type Activite = {
    code: string
    description: string
}

export type Couleur = {
    code: string
    description: string
}

export type FarbriqueA = {
    code: string
    description: string
}

export type Matiere = {
    code: string
    description: string
}

export type Modele = {
    code: string
    description: string
}

export type SousActivite = {
    code: string
    description: string
}

export type TypeProduit = {
    code: string
    description: string
}

export type Produit = {
    code: string
    activite: Activite
    sousActivite: SousActivite
    modele: Modele
    matiere: Matiere
    couleur: Couleur
    fabriqueA: FarbriqueA
    type: TypeProduit
    sketch: string
    grilleTailleCode: string
    gender: string
}

export type QuantiteTailleStatut = {
    statutSku: StatutSkuCode
    tailleCode: string
    quantite: number | null
}

export class FlatOfReferenceProduit {
    of: OrdreDeFabrication
    referenceProduit: ReferenceProduit
}

export type Saison = {
    code: string
    description: string
}

export type Fabricant = {
    code: string
    label: string
}

export type OrdreDeFabrication = {
    numero: string
    fabricantCode: string
    fabricant: Fabricant
    saisonCode: string
    saison: Saison
    dateCreation: Date
    typeOrdreDeFabrication: string
    groupage: string | null
    referenceProduits: ReferenceProduit[]
}

export type OrdreDeFabricationFiltered = {
    ofs: OrdreDeFabrication[]
    total: number
    filters: Filters
}


export type Filters = {
    number: string[]
    manufacturer: Fabricant[]
    season: Saison[]
    typeWO: string[]
    group: string[]
    status: string[]
    subActivity: SousActivite[]
    type: TypeProduit[]
    gender: string[]
    model: Modele[]
    material: Matiere[]
    color: Couleur[]
}
export type FiltersPost = {
    Number: string[]
    ManufacturerCode: string[]
    SeasonCode: string[]
    TypeWO: string[]
    Group: string[]
    Status: string[]
    SubActivityCode: string[]
    TypeCode: string[]
    Gender: string[]
    ModelCode: string[]
    MaterialCode: string[]
    ColorCode: string[]
}

export type FilterAsn = {
    ManufacturerCode: string[]
    Status: string[]
    NumberAsn: string[]
    NumberWo: string[]
    SeasonCode: string[]
    TypeWO: string[]
    Group: string[]
    StatusWo: string[]
    SubActivityCode: string[]
    TypeCode: string[]
    Gender: string[]
    ModelCode: string[]
    MaterialCode: string[]
    ColorCode: string[]
    ShippingDateFrom: string
    ShippingDateTo: string
}

export type StatutSku = {
    code: StatutSkuCode
    order: number
}

export type StatutReferenceProduit = {
    code: string
    isSelectable: boolean
}

export type StatutSkuCode = string

export type Semaine = { annee: number; numero: number }
export type QuantiteStatut = { statutSku: StatutSkuCode; quantite: number }

export type QuantiteSemaine = {
    id: string
    referenceProduitId: string
    semaine: Semaine
    quantite?: number
    statutSemaine: string
}

export type ReferenceProduit = {
    id: string
    ordreDeFabricationNumero: string
    produitCode: string
    statut: string
    magasinCode: string
    fabricantValidatedInitial: boolean
    fabricantValidatedInitialAt: Date
    celineValidatedInitial: boolean
    celineValidatedInitialAt: Date
    produit: Produit
    quantiteTailleStatuts: QuantiteTailleStatut[]
    quantiteStatuts: QuantiteStatut[]
    quantiteSemaines: QuantiteSemaine[]
}

export type PlanifierExpedition = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    Semaine: Semaine
    Quantite: number | null
    IsInitialPlanning: boolean
}

export type ModifierStatutOrdreDeFabrication = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    Statut: string
}

export enum StatutSemaine {
    Initial = 'Initial',
    Planifie = 'Planifie',
    Expedie = 'Expedie',
}

export enum StatutSkus {
    Commande = 'Commande',
    EnTransit = 'EnTransit',
    Recu = 'Recu',
    ResteAExpedier = 'ResteAExpedier',
    Annule = 'Annule',
    ResteAPlanifier = 'ResteAPlanifier',
}

export enum StatutRefProduit {
    Created = 'Created',
    Started = 'Started',
    Suspended = 'Suspended',
    Closed = 'Closed',
    Alert = 'Alert',
}

export type CreerCommentaire = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    Contenu: string
    IsAlert: boolean
    IsSuspension: boolean
}

export type ModifierCommentaire = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    Contenu: string
    CommentaireId: Guid
}

export type ToggleAlerteCommentaire = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    CommentaireId: Guid
    IsNewAlert: boolean
}

export type Commentaire = {
    id: Guid
    contenu: string
    lastModificationDate: Date
    userName: string
    isAlert: boolean
    isSuspension: boolean
    referenceProduitId: Guid
}

export type LastCommentaireReferenceProduit = {
    referenceProduitId: Guid
    lastCommentaire: Commentaire
    countCommentaire: Number
    hasAlert: boolean
}

export type ValiderPlanningInitial = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    UserGroup: string
    ValidationSource: string
}

export type AnnulerPlanningInitial = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    UserGroup: string
    AnnulationSource: string
}

export type AnnulerQuantiteCommandee = {
    OrdreDeFabricationNumero: string
    ProduitCode: string
    Taille: string
    Quantite: number
}

export enum ValiderPlanningInitialSource {
    Celine = 'celine',
    Fabricant = 'fabricant',
}

export type PlanificationProductionToExport = {
    Manufacturer: string
    SaisonCode: string
    SaisonDescription: string
    WOType: string
    Groupage: string
    WorkOrder: string
    Status: string
    Activity: string
    SubActivity: string
    Type: string
    Gender: string
    Product: string
    ModelCode: string
    ModelDescription: string
    MaterialCode: string
    MaterialDescription: string
    ColorCode: string
    ColorDescription: string
    QuantiteStatuts: { [key: string]: number }
    LineType: string
    QuantiteSemaines: { [key: string]: number }
}

export type ShipmentToExport = {
    AsnNumber: string
    AsnStatus: string
    AsnShipDate: string
    LastUpdate: string
    AsnTotalShipped: string
    Warehouse: string
    ManufacturerCode: string
    ManufacturerDescription: string
    SubActivity: string
    OfNumber: string
    OfStatus: string
    OfType: string
    Season: string
    ModelCode: string
    ModelDescription: string
    MaterialCode: string
    MaterialDescription: string
    ColorCode: string
    ColorDescription: string
    Gender: string
    TypeCode: string
    TypeDescription: string
    ProductCode: string
    SizeGrid: string
    QuantitiesBySize: { [key: string]: number }
}
